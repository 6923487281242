import React from 'react'
import '../css/testmoni.css'


export default function Board2() {
  return (
   <>
    <div className="board-container">
        <div className="board">
            <h4>Want to transform yourself, but have many questions?</h4>
            <a href="/contact" target='_blank'><button className='btn '>Get a free Video consultation</button></a>
        </div>
    </div>
   </>
  )
}
