import React, { useState } from 'react';
import './css/home.css';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    communication: '',
    time: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Error sending email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }

    // Reset form fields
    setFormData({
      name: '',
      phone: '',
      communication: '',
      time: '',
      message: ''
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your name*" required />
      <input type="text" name="phone" value={formData.phone} onChange={handleChange} placeholder="Your Phone*" required />
      <div className="form-div">
      <select name="communication" value={formData.communication} onChange={handleChange} className='communication2' required>
        <option value="">Select communication method</option>
        <option value="Face Time">Face Time</option>
        <option value="Google Meet">Google Meet</option>
        <option value="Zoom">Zoom</option>
        <option value="Whatsapp">Whatsapp</option>
        <option value="Phone Call">Phone Call</option>
      </select>
      {/* <input type="text" name="time" value={formData.time} onChange={handleChange} className='Virtual' placeholder="Best time to call" required /> */}
      <select name="communication" value={formData.communication} onChange={handleChange} className='communication2' required>
  <option value="">Best time to call</option>
  {Array.from({ length: 24 }, (_, i) => i).map(hour => (
    <option key={hour} value={`${hour % 12 === 0 ? 12 : hour % 12}:00 ${hour >= 12 ? 'pm' : 'am'}`}>
      {`${hour % 12 === 0 ? 12 : hour % 12}:00 ${hour >= 12 ? 'pm' : 'am'}`}
    </option>
  ))}
</select>


      </div>
      <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Put your query..." required></textarea>
      <input type="submit" value="Submit" />
    </form>
  );
}

export default ContactForm;

