import React from 'react'
import { BrowserRouter as Router, Routes, Route , Link} from 'react-router-dom';

import './css/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPaperPlane, faPhone, faEnvelope, faVideo } from '@fortawesome/free-solid-svg-icons';


// import qr1 from './img/home/qr1.jpg'
// import qr2 from './img/home/qr2.png'

export default function Footer() {
  return (
    <>
      <footer>
        
        <div className="footer-container">
          <div className="footer-box">
            <h5>Learn More About Dr.Garg</h5>
            <div className="qr-img">
              <a href="https://www.cosmeticsurgery.org/member/shapeudeserve" target='_blank'> <img src="https://www.shapeudeserve.com/wp-content/uploads/2023/03/WhatsApp-Image-2023-03-29-at-03.47.10-150x150.jpg" alt="" /></a>
              <a href="https://www.americanboardcosmeticsurgery.org/doctors/ashu-garg-md/" target='_blank'><img src="https://www.shapeudeserve.com/wp-content/uploads/2023/04/qr-code-1-150x150.png" alt="" /></a>
            </div>
            <div className="contact">
              <h5>Contact Us</h5>
              <p><FontAwesomeIcon icon={faPhone} />  +1 424-666-1260</p>
              <p><FontAwesomeIcon icon={faPhone} /> +1 424-944-2004</p>
              <p><FontAwesomeIcon icon={faPaperPlane} />  care@shapeudeserve.com</p>
              <p><FontAwesomeIcon icon={faEnvelope} />  drgarg@shapeudeserve.com</p>
            </div>
          </div>


          <div className="footer-box">
            <h5>Procedures</h5>
            <div className="qr-img">
              <ul>
                <li><Link to="">LIPOSUCTION</Link></li>
                <li><Link to="/brazilian-butt-lift">BBL</Link></li>
                <li><Link to="">TUMMY TUCK</Link></li>
                <li><Link to="">MOMMY MAKEOVER</Link></li>
                <li><Link to="/breast-augmentation-surgery">BREAST AUGMENTATION</Link></li>
                <li><Link to="">BREAST LIFT</Link></li>
                <li><Link to="">BLEPHAROPLASTY</Link></li>
                <li><Link to="/buccal-fat-removal">BUCCAL FAT REMOVAL</Link></li>
                <li><Link to="/facelift-surgery">FACE LIFT</Link></li>
                <li><Link to="">BROW LIFT</Link></li>
                <li><Link to="/body-left-procedure">BODY LIFT</Link></li>
                <li><Link to="/gynecomastia-surgery">GYNAECOMATIA</Link></li>
                <li><Link to="">BREAST REDUCTION</Link></li>
                <li><Link to="/breast-revision-surgery">BREAST REVISION SURGERY</Link></li>
              </ul>
            </div>
          </div>

          <div className="footer-box">
            <div className='m-auto'>
              <h5>Get a Quote</h5>
              <form>
                <div class=" name-email">
                  <span>
                    <input type="text" id="name" name="name" className='name1' placeholder="Your Name*" required />
                  </span>
                  <span>
                    <input type="email" id="email" name="email" className='email1' placeholder="Add email*" required />
                  </span>
                </div>
                <div class="name-email phone-com ">
                  <span className=' phone-com'>
                    {/* <label for="phone">Phone Number:</label> */}
                    <input type="tel" id="phone" className='phone' name="phone" placeholder="Phone Number*" required />
                  
                    {/* <label for="communication">Preferred :</label> */}
                    <select id="communication" name="communication"  className='communication1' type="option" required>
                      <option value="phone">Face Time</option>
                      <option value="phone">Google Meet</option>
                      <option value="phone">Zoom</option>
                      <option value="phone">Whatsapp</option>
                      <option value="email">Phone Call</option>
                    </select>
                
                  </span>
                 
                </div>
                <div>
                  {/* <label for="interest">Interest In:</label> */}
                  <input type="text" id="interest" name="interest" placeholder="Interested In" required />
                </div>
                <div>
                  {/* <label for="comment">Your Comment:</label> */}
                  <textarea id="comment" name="comment" placeholder="Your Comments / Questions"></textarea>
                </div>
                <button type="submit" className='submit'>  <FontAwesomeIcon icon={faVideo} />  Ask for Free Video Consultation</button>
              </form>
            </div>
          </div>
          
        </div>
        {/* <hr /> */}
        <div className="copy-right">
          <div className="copy-right-sec">
            © Copyright 2024 | Shape U Deserve | All rights reserved
            Privacy policies | <Link to="https://www.abroad-media.com/" target='_blank'>Powered by Abroad-Media</Link>
          </div>
          <div className="policy">
          <Link to="/privacy-policy"> Cookie Policy </Link> | <Link to="/terms-conditions"> Disclaimer </Link>  | <Link to="/disclaimer"> Privacy Policy </Link> | <Link to="/cookie-policy"> Terms & Conditions </Link>

          </div>
        </div>
      </footer>
    </>
  )
}
