import React from 'react';
import './css/card.css'; // Rename the CSS import to avoid conflict

//Import Images
import img1 from './img/home/back.jpg';
import img2 from './img/home/face.jpg';
import img3 from './img/home/breast.jpg';
import img4 from './img/home/lips.jpg';

export default function CardComponent() { // Rename the function name
    return (
        <>
            <div className="containerre">
                <div className="box-container">

                    <div className="box">
                        <img src={img1} alt="" />
                        <h2 className="text1">Body</h2>
                        <div className="shadow">
                            <ul className="content1">
                                <li>Tummy Tuck</li>
                                <li>Lipsuction</li>
                                <li>Mummy Makeover</li>
                                <li>Brazilian butt lift- BBL</li>
                            </ul>
                        </div>
                    </div>

                    <div className="box">
                        <img src={img2} alt="" />
                        <h2 className="text1">Face</h2>
                        <div className="shadow">
                            <ul className="content1">
                                <li>Eyelid Lift- Blepharoplasty</li>
                                <li>Face Lift</li>
                                <li>Buccal Fat Removal</li>
                                <li>Neck and Chin Liposuction</li>
                            </ul>
                        </div>
                    </div>

                    <div className="box box2">
                        <img src={img3} alt="" />
                        <h2 className="text1">Breasts</h2>
                        <div className="shadow">
                            <ul className="content1">
                                <li>Breast Augmentation</li>
                                <li>Breast Lift</li>
                                <li>Breast Reduction</li>
                                <li>Breast Revision Surgery</li>
                            </ul>
                        </div>
                    </div>

                    <div className="box box1 box44">
                        <img src={img4} alt="" />
                        <h2 className="text1 text11">Non-Surgical</h2>
                        <div className="shadow">
                            <ul className="content1">
                                <li>Botox and Fillers</li>
                                <li>Morpheus 8</li>
                                <li>Potenza</li>
                                <li>Skin Aesthetic Procedure</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
