import React from 'react'
import '../css/Pages.css';

export default function FinanceAndPayments() {
    return (
        <>

            <div className="financing">
                <div className="f-content"><h2>Easy Financing for you by our Partners</h2></div>
                <div className="f-img"><img src="https://www.shapeudeserve.com/wp-content/uploads/2023/04/istockphoto-1249761745-612x612-1.jpg" alt="" height={300} /></div>
            </div>


            <div className="apply-content-container">
                <div className="apply-content">
                    <div className="a-content">
                        <h4>Financing With United Credit</h4>
                        <button type="button" class="btn btn-secondary">Apply Now</button>
                    </div>
                    <div className="a-logo"><img src="https://www.shapeudeserve.com/wp-content/uploads/2023/04/new-stripe-logo-png.png  " alt="" height={100} /></div>
                </div>

                <div className="apply-content gery-black">
                    <div className="a-content">
                        <h4>Financing With United Credit</h4>
                        <button type="button" class="btn btn-secondary">Apply Now</button>
                    </div>
                    <div className="a-logo"><img src="https://www.shapeudeserve.com/wp-content/uploads/2023/03/3.3.png" alt="" height={200} /></div>
                </div>

                <div className="apply-content">
                    <div className="a-content">
                        <h4>Financing With Patient Fi</h4>
                        <button type="button" class="btn btn-secondary">Apply Now</button>
                    </div>
                    <div className="a-logo"><img src="https://www.shapeudeserve.com/wp-content/uploads/2023/03/PatientFi_Logo_-_Pantone.jpg" alt="" height={100} /></div>
                </div>


                <div className="apply-content gery-black">
                    <div className="a-content">
                        <h4>Financing With Cherry</h4>
                        <button type="button" class="btn btn-secondary">Apply Now</button>
                    </div>
                    <div className="a-logo"><img src="https://www.shapeudeserve.com/wp-content/uploads/2023/03/3.5.png" alt="" height={200} /></div>
                </div>


                <div className="apply-content">
                    <div className="a-content">
                        <h4>Financing With DenFits</h4>
                        <button type="button" class="btn btn-secondary">Apply Now</button>
                    </div>
                    <div className="a-logo"><img src="https://www.shapeudeserve.com/wp-content/uploads/2023/03/3.2.png" alt="" height={200} /></div>
                </div>


                <div className="apply-content gery-black">
                    <div className="a-content">
                        <h4>Financing With Momnt</h4>
                        <button type="button" class="btn btn-secondary">Apply Now</button>
                    </div>
                    <div className="a-logo"><img src="https://www.shapeudeserve.com/wp-content/uploads/2023/04/momnt-logo-large-removebg-preview.png" alt="" height={100} /></div>
                </div>


                <div className="apply-content">
                    <div className="a-content">
                        <h4>Financing With Care Credit</h4>
                        <button type="button" class="btn btn-secondary">Apply Now</button>
                    </div>
                    <div className="a-logo"><img src="https://www.shapeudeserve.com/wp-content/uploads/2023/10/carecre-1.jpg" alt="" height={100} /></div>
                </div>
            </div>


        </>
    )
}
