import React from 'react'
import '../css/testmoni.css'


export default function Board() {
  return (
   <>
    <div className="board-container">
        <div className="board">
            <h4>Dr. Garg is a Diplomat of American Board of Cosmetic Surgery</h4>
            <a href="https://www.americanboardcosmeticsurgery.org/doctors/ashu-garg-md/" target='_blank'><button className='btn '>View More</button></a>
        </div>
    </div>
   </>
  )
}
